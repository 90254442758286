import { React, useState, useEffect } from 'react';

import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell,StyledTableFooterCell, NumberFormat } from '../../components';

import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import '../../App.css';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    // maxHeight: 440
    maxHeight: '90vh'
  }
});

let prevProdType='';
let prevfrmDate;
let prevtoDate;
const ProdRawMaterial = (props) => {
  const classes = useStyles();
  //------------FETCH API------------------
  const [ProductionRM_DATA, RMhandleStats] = useState([]);

  useEffect(() => {
    FetchRMData()
    }, [])
  const apiName = props.ProdType === 'Rags' ? 'ProductionAnalysisRM' : props.ProdType === 'Wiper' ? 'ProductionAnalysisWPRM' : '';
  const FetchRMData = async () => {
    const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/'+apiName+'?frmDate='+props.setDates.frmDate+'&toDate='+props.setDates.toDate); //data source
    const ProductionRM_DATA = await data.json()
    RMhandleStats(ProductionRM_DATA);
    setFilter(ProductionRM_DATA);

  }
  if((prevProdType !==  props.ProdType) ||(prevfrmDate !==  props.setDates.frmDate) || (prevtoDate !==  props.setDates.toDate))
  {
    FetchRMData();
    prevProdType = props.ProdType;
    prevfrmDate =  props.setDates.frmDate;
    prevtoDate = props.setDates.toDate;
  }
  // sajjad start

  //---------- Searching-----------------
const [filter, setFilter] = useState([])
const [showClearIcon, setShowClearIcon] = useState("none");
const requestSearch = (searchedVal) => {
  setShowClearIcon(searchedVal === "" ? "none" : "flex");
  document.getElementById('searchtextRM').value = searchedVal === "" ? "" : searchedVal;
  const filteredRows = ProductionRM_DATA.filter((row) => {
    return row.PurhLocation.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
  });
  if (searchedVal.length < 1) {
    setFilter(ProductionRM_DATA)
  }
  else {
    setFilter(filteredRows)
  }
};
  // sajjad end
  //-----------------TOTAL
  //----TOTAL----------------------
  let total_NoofBales = 0; // Total No Of Bales
  let total_Weight = 0; // Total Weight
  let total_Per = 0; // Total Per

  ProductionRM_DATA.map((row) => (total_NoofBales += row.NoOfBales, total_Weight += row.Act_Weight, total_Per += row.Percentage));


  return (
    <div>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
        <HeaderSM category="" title={'RAW MATERIAL '+ (props.ProdType === 'Rags' ? '(Mix Rags)' : '(WIPER)')} />
      {/* sajjad start */}
        <div style={{ marginTop: "-1.5rem" }}>
        <TextField id='searchtextRM' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => requestSearch("")}
                      style={{ display: showClearIcon }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  )
                }}
              />
              </div>
              {/* sajjad end */}
        <TableContainer className={classes.container}>
          <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
            <TableHead >
              <TableRow>
                <StyledTableHeadCell align="left">Location & Supplier</StyledTableHeadCell>
                <StyledTableHeadCell align="right">No. Of Bales</StyledTableHeadCell>
                <StyledTableHeadCell align="right">Per%</StyledTableHeadCell>
                <StyledTableHeadCell align="right">Weight</StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filter.map((row) => (
                <StyledTableRow key={row.Id} hover >
                  <StyledTableCell align="left"><div>{row.PurhLocation} </div> {row.SpName}</StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.NoOfBales} decimal={0} /></StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.Percentage} decimal={2} />%</StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.Act_Weight} decimal={2} /></StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <StyledTableFooterCell align="right" style={{ fontWeight: "Bold"  }}>Total</StyledTableFooterCell>
                <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_NoofBales} decimal={0} /></StyledTableFooterCell>
                <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Per} decimal={2} />%</StyledTableFooterCell>
                <StyledTableFooterCell align="right" style={{ fontWeight: "Bold"  }}><NumberFormat value={total_Weight} decimal={2} /></StyledTableFooterCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div >
  )
}

export default ProdRawMaterial