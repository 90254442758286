import React from 'react'
import {
  TableRow
} from "@material-ui/core";
import { styled } from '@mui/material/styles';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
 
    '&:nth-of-type(odd)': {
      backgroundColor: "white",
    },
    '&:nth-of-type(even)': {
      backgroundColor: "#fafafa",
    },
   
  }));


export  default StyledTableRow
