import { React, useState, useEffect, useCallback } from 'react';
import { PieChart, Pie, Legend, Tooltip, Cell, ResponsiveContainer, Symbols, Surface } from 'recharts';
import '../../App.css'
import { Header, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat } from '../../components';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel
} from "@material-ui/core";
import Button from '@mui/material/Button';



const OrderPieChart = (props) => {


  const changecomponent = (rowdata) => {
    props.getOrderNo({ 'OrderNo': rowdata.OrderNo,'OrderIntRefNo':rowdata.OrderIntRefNo,'OrderDate':rowdata.OrderDate });
    props.actvCompName('OrderDetail');

  }
  
  const [OrderGraphData, handleStats] = useState([]);
  useEffect(() => {
    FetchSecTypeData()
  }, [])

  const FetchSecTypeData = async () => {
    const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/saleOrdersumm/');
    const OrderGraphData = await data.json()
    handleStats(OrderGraphData)
  }







  // console.log(OrderGraphData)
  // const COLORS = ["#A020F0", "#CBC3E3"]
  const COLORS = [
    //{ start: "#9e54ed", end: "#5c4cb6" },
    // { start: "#34c3ff", end: "#2876bd" },
    // { start: "#9733EE", end: "#DA22FF" },
     { start: "#b06ab3", end: "#4568dc" },
     { start: "#4ca1af", end: "#c4e0e5" },
  ]

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);



    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "middle"}
        dominantBaseline="central"
        
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


  return (

    <div>
      <div>
        {OrderGraphData.map((row) => ( 
          <div key={row.Id} className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page OrderChart">
            {/* <Header category="" title={row.OrderIntRefNo} OtherInfo={'Priority # ' + row.OrderPirority} className='piechartHead' /> */}
            
            <div className="piechartHead">
              <p className="text-3xl font-extrabold tracking-tight text-slate-900 header-title">
              <p className="header-info"  onClick={(e) => changecomponent(row)} style={{ cursor: 'pointer' }}>
             {row.OrderIntRefNo} -  Priority #  {row.OrderPirority}
                </p>
              </p>
            </div>

            <TableContainer style={{marginTop:"-1rem"}}>
              <Table id="myTable" aria-label="sticky table" size={'small'}>
                <TableHead >
                  <TableRow style={{ backgroundColor: "transparent" }}>
                    <StyledTableHeadCell align="center">Order</StyledTableHeadCell>
                    <StyledTableHeadCell align="center">Ready</StyledTableHeadCell>
                    <StyledTableHeadCell align="center"> Balance</StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableCell align="center" ><NumberFormat value={row.OrderQnty} decimal={0} /></StyledTableCell>
                  <StyledTableCell align="center"><NumberFormat value={row.ReadyQnty} decimal={0} /></StyledTableCell>
                  <StyledTableCell align="center"><NumberFormat value={row.BalanceQnty} decimal={0} /></StyledTableCell>
                </TableBody>
              </Table>
            </TableContainer>
            {/* <p> Order Bales : {row.OrderQnty} <br/> Ready Bales : {row.ReadyQnty} <br/> Balance Bales : {row.BalanceQnty}</p> */}
            {/* <div> */}
            <div style={{display:"flex", justifyContent:"center"}}>
            <ResponsiveContainer width={200} height={200} >
              <PieChart >
                <defs>
                  {OrderGraphData.map((entry, index) => (
                    <linearGradient key={index} id={`myGradient${index}`}>
                      <stop
                        offset="0%"
                        stopColor={COLORS[index % COLORS.length].start}
                      />
                      <stop
                        offset="100%"
                        stopColor={COLORS[index % COLORS.length].end}
                      />
                    </linearGradient>
                  ))}
                </defs>
                <Legend verticalAlign="top" align='center' layout='horizontal'
                //  payload={[
                //   { value: 'Order '+row.OrderQnty,type: 'line', color: "",},
                //   { value: 'Ready '+row.ReadyQnty, type: 'square', color: "#9e54ed"},
                //   { value: 'Balance '+row.BalanceQnty, type: 'square', color: "#34c3ff" },
                // ]} 
                />
                <Tooltip />
                <Pie
                  data={[
                    { name: "Ready", value: row.ReadyQnty },
                    { name: "Balance", value: row.BalanceQnty },
                  ]}
                  cx={100}
                  cy={100}
                  labelLine={false}
                  legendType={'square'}
                  label={renderCustomizedLabel}
                  outerRadius={70}
                  // fill="#8884d8"
                  dataKey="value"
                // stroke='#00000'
                >
                  {OrderGraphData.map((entry, index) => (
                    //    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    <Cell key={`cell-${index}`} fill={`url(#myGradient${index})`} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            </div>
          </div>
        )).slice(0, 12)}

      </div>
    </div>

  )
}

export default OrderPieChart