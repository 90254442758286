
import { React, useState, useEffect } from 'react';
import { ProdRawMaterial, ProdFGSecType, ProdFGSections, ProdFGSecDetail } from '../';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@mui/material';
import '../../App.css';
import { MdOutlineFilterList } from "react-icons/md";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from "dayjs";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { subMonths, isMatch } from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
import { TextField } from "@material-ui/core";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import validator from 'validator'

const { format } = require('date-fns');
let prevProdType;
const ProductionAnalysis = (props) => {
  const [activeCompoennt, setactiveCompoennt] = useState('SecType');
  const [SecType, setSecType] = useState({ ItemBaleType: '', ItemBaleType_Desc: '' });
  const [section, setsections] = useState({ Sec_Code: '', Sec_Name: '', ItemBaleType: '' });
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [frmProddate, setfrmProdDate] = useState(new Date());
  const [toProddate, settoProdDate] = useState(new Date());

  const Changepage = (compName) => {
    setactiveCompoennt(compName);
  }
  if(props.ProdType != prevProdType)
  {
    setactiveCompoennt('SecType');
    prevProdType = props.ProdType;
  }
  let showData = true;

  // const todaydate = format(new Date(), 'yyyy-MM-dd');//moment();
  // const PrevYeardate = format(subMonths(new Date(), 12), 'yyyy-MM-dd');//moment().subtract(1, 'year')
  // console.log(PrevYeardate);
  // let frmProdDate = format(new Date(), 'yyyy-MM-dd');
  // let toProdDate =  format(new Date(), 'yyyy-MM-dd');

  function getDates() {
    const $frmDate = format(new Date(frmProddate), 'yyyy-MM-dd');
    const $toDate = format(new Date(toProddate), 'yyyy-MM-dd');
    if ($frmDate > $toDate) {
      setError(true);
      setOpen(true);
      showData = false;

    }
    else {
      showData = true;
      setError(false);
      setOpen(false);
      setfrmProdDate($frmDate);
      settoProdDate($toDate);
    }

  }

  return (
    <div>
      {error && <Collapse in={open}>  <Alert severity="warning" onClose={() => { setOpen(false) }}>From Date should be less than To Date</Alert></Collapse>}

      <div className='sameLineTable'>
        <div className='sameLineTableDatediv'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="From Date"
              autoComplete='off'
              value={frmProddate}
              onChange={(newValue) => {
                if (newValue > toProddate) {
                  setError(true);
                  setOpen(true);
                }
                else {
                  setError(false);
                  setOpen(false);
                  if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                    setfrmProdDate(newValue);
                  }
                }
              }}
              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => <TextField {...params} />}
              className="sameLineTabledatetime"
            />
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <DesktopDatePicker
              label="To Date"
              value={toProddate}
              onChange={(newValue) => {
                if (frmProddate > newValue) {
                  setError(true);
                  setOpen(true);
                }
                else {
                  setError(false);
                  setOpen(false);
                  if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                    settoProdDate(newValue);
                  }
                }
              }}

              inputFormat={"DD/MM/YYYY"}
              renderInput={(params) => <TextField {...params} />}
              className="sameLineTabledatetime"
            />
          </LocalizationProvider>
          <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
            <Button className='filterButton' onClick={getDates}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>
          </ButtonGroup>

        </div>
        <ProdRawMaterial ProdType={props.ProdType} setDates={{ 'frmDate': format(new Date(frmProddate), 'yyyy-MM-dd'), 'toDate': format(new Date(toProddate), 'yyyy-MM-dd') }} />
      </div>
      <div className='sameLineTable'>
        <div className="set-breadcrumb">
        {activeCompoennt === 'SecType' && <Breadcrumbs aria-label="breadcrumb">  <Button></Button> </Breadcrumbs>}
         {activeCompoennt !== 'SecType' && <Breadcrumbs aria-label="breadcrumb">  
            <Button className="hover-underline-animation" style={{ color: 'purple' }} onClick={(e) => Changepage('SecType')}>
              Secion types
            </Button>
            {(activeCompoennt === 'Sections' || activeCompoennt === 'SectionDetail') &&
              <Button className={activeCompoennt === 'SectionDetail' ? "hover-underline-animation" : ""} disabled={activeCompoennt === 'Sections' && true} onClick={(e) => Changepage('Sections')}>
                <Link underline="none" color={activeCompoennt === 'SectionDetail' && "purple"} >
                  Sections
                </Link>
              </Button>
            }
            {activeCompoennt === 'SectionDetail' &&
              <Button disabled={true}> Section Detail </Button>
            }
          </Breadcrumbs>}
        </div>
        {activeCompoennt === 'SecType' && <ProdFGSecType ProdType={props.ProdType} getSecType={setSecType} setDates={{ 'frmDate': format(new Date(frmProddate), 'yyyy-MM-dd'), 'toDate': format(new Date(toProddate), 'yyyy-MM-dd') }} actvCompName={setactiveCompoennt} />}
        {activeCompoennt === 'Sections' && <ProdFGSections ProdType={props.ProdType} SecTypes={SecType} getSectionDet={setsections} setDates={{ 'frmDate': format(new Date(frmProddate), 'yyyy-MM-dd'), 'toDate': format(new Date(toProddate), 'yyyy-MM-dd') }} actvCompName={setactiveCompoennt} />}
        {activeCompoennt === 'SectionDetail' && <ProdFGSecDetail ProdType={props.ProdType} SecDetail={section} setDates={{ 'frmDate': format(new Date(frmProddate), 'yyyy-MM-dd'), 'toDate': format(new Date(toProddate), 'yyyy-MM-dd') }} />}
      </div>

    </div>
  )
}

export default ProductionAnalysis
