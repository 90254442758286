
import { React, useState } from 'react';
import { FinancialTB,FinancialGL } from '../';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Button from '@material-ui/core/Button';
import { subMonths } from 'date-fns'
import { ButtonGroup } from '@mui/material';
import '../../App.css';

const { format } = require('date-fns');

const OrderAnalysis = () => {
    const [activeCompoennt, setactiveCompoennt] = useState('TB');
    const [getGL, setGL] = useState({AcCode: '',CcCode:'',CoaDesc:'' });
    const Changepage = (compName) => {
        setactiveCompoennt(compName);
      }
     
  return (
    <div>
         {activeCompoennt !== 'TB' && <div className="set-breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">
           <Button className="hover-underline-animation" style={{ color:  'purple' }}  onClick={(e) => Changepage('TB')}>
              Trial Balance
            </Button>
            {activeCompoennt === 'OrderDetail' &&
              <Button disabled={true}> General Ledger </Button>
            }
          </Breadcrumbs>
        </div>}
        {activeCompoennt === 'TB' &&  <FinancialTB getGLFilter={setGL} actvCompName={setactiveCompoennt} />}
        {activeCompoennt === 'GL' && <FinancialGL  setGLFilter={getGL}/>}    
    </div>
  )
}

export default OrderAnalysis