import { React, useState, useEffect } from 'react';

import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell,StyledTableFooterCell, NumberFormat } from '../../components';

import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
// start
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// end
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import '../../App.css';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    // maxHeight: 440
    maxHeight: '90vh'
  }
});

let Page;
let prevProdType='';
let prevfrmDate;
let prevtoDate;

const ProdFGSecType = (props) => {

  const changecomponent = (rowdata) => {

    props.getSecType({ 'ItemBaleType': rowdata.ItemBaleType, 'ItemBaleType_Desc': rowdata.ItemBaleType_Desc });
    props.actvCompName('Sections');

  }

  const classes = useStyles();
  const [ProductionSecType_DATA, handleStats] = useState([]);
  useEffect(() => {
    FetchSecTypeData()
  }, [])
    const apiName = props.ProdType === 'Rags' ? 'ProductionAnalysisSecType' : props.ProdType === 'Wiper' ? 'ProductionAnalysisWPSecType' : '';
  const FetchSecTypeData = async () => {
    const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/'+apiName+'?frmDate='+props.setDates.frmDate+'&toDate='+props.setDates.toDate);
    const ProductionSecType_DATA = await data.json()
    handleStats(ProductionSecType_DATA);
    // start
    setFilter(ProductionSecType_DATA);
    // end

  }
  if((prevProdType !==  props.ProdType) || (prevfrmDate !==  props.setDates.frmDate) || (prevtoDate !==  props.setDates.toDate))
  {
    FetchSecTypeData();
    prevProdType = props.ProdType;
    prevfrmDate =  props.setDates.frmDate;
    prevtoDate = props.setDates.toDate;
  }

    // start
  //---------- Searching-----------------
const [filter, setFilter] = useState([])
const [showClearIcon, setShowClearIcon] = useState("none");
const requestSearch = (searchedVal) => {
  setShowClearIcon(searchedVal === "" ? "none" : "flex");
  document.getElementById('searchtextSecType').value = searchedVal === "" ? "" : searchedVal;
  const filteredRows = ProductionSecType_DATA.filter((row) => {
    return row.ItemBaleType_Desc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
  });
  if (searchedVal.length < 1) {
    setFilter(ProductionSecType_DATA)
  }
  else {
    setFilter(filteredRows)
  }
};
  // end
//-----------------TOTAL
  //----TOTAL----------------------
  let total_NoofBales = 0; // Total No Of Bales
  let total_Weight = 0; // Total Weight
  let total_Per = 0; // Total Per

  ProductionSecType_DATA.map((row) => (total_NoofBales += row.NoOfBales, total_Weight += row.ItemWeight, total_Per += row.Percentage));


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
      <HeaderSM category="" title="Section Types" />
{/* start */}
<div style={{ marginTop: "-1.5rem" }}>
        <TextField id='searchtextSecType' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => requestSearch("")}
                      style={{ display: showClearIcon }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  )
                }}
              />
              </div>
{/* end */}
      <TableContainer className={classes.container}>
        <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
          <TableHead >
            <TableRow>
              <StyledTableHeadCell align="left">Bale Type</StyledTableHeadCell>
              <StyledTableHeadCell align="right">No. Of Bales</StyledTableHeadCell>
              <StyledTableHeadCell align="right">Per%</StyledTableHeadCell>
              <StyledTableHeadCell align="right">Weight</StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filter.map((row) => (
              <StyledTableRow key={row.Id} hover >
                <StyledTableCell align="left"> <Button style={{ fontWeight: "400", textAlign: "left" }} size='small' color="inherit" variant='text' onClick={(e) => changecomponent(row)}> {row.ItemBaleType_Desc}</Button></StyledTableCell>
                <StyledTableCell align="right"><NumberFormat value={row.NoOfBales} decimal={0} /></StyledTableCell>
                <StyledTableCell align="right"><NumberFormat value={row.Percentage} decimal={2} />%</StyledTableCell>
                <StyledTableCell align="right"><NumberFormat value={row.ItemWeight} decimal={2} /></StyledTableCell>
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Total</StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_NoofBales} decimal={0} /></StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Per} decimal={2} />%</StyledTableFooterCell>
              <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Weight} decimal={2} /></StyledTableFooterCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ProdFGSecType