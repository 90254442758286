import { React, useState, useEffect, useCallback } from 'react';

import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell,StyledTableFooterCell, NumberFormat } from '../../components';

import { BrowserRouter as Router, Route, NavLink, Routes } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import '../../App.css';

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    // maxHeight: 440
    maxHeight: '90vh'
  }
});


let prevProdType;
let prevfrmDate;
let prevtoDate;

const ProdFGSecDetail = (props) => {

  const classes = useStyles();
  const [ProductionSecDet_DATA, handleStats] = useState([]);
  useEffect(() => {
    FetchSecDetail()
  }, [])
    const apiName = props.ProdType === 'Rags' ? 'ProductionAnalysisFG' : props.ProdType === 'Wiper' ? 'ProductionAnalysisWPFG' : '';
    const FetchSecDetail = async () => {
    const data = await fetch(process.env.REACT_APP_SERVER_NAME + "/api/"+apiName+"?Sec_Code=" + props.SecDetail.Sec_Code + "&Sec_Desc=" + props.SecDetail.Sec_Desc + "&ItemBaleType=" + props.SecDetail.ItemBaleType+'&frmDate='+props.setDates.frmDate+'&toDate='+props.setDates.toDate); //data source props.baleType
    const ProductionSecDet_DATA = await data.json()
    handleStats(ProductionSecDet_DATA)
    setFilter(ProductionSecDet_DATA);
  }
  if((prevProdType !==  props.ProdType) || (prevfrmDate !==  props.setDates.frmDate) || (prevtoDate !==  props.setDates.toDate))
  {
    FetchSecDetail();
    prevProdType = props.ProdType;
    prevfrmDate =  props.setDates.frmDate;
    prevtoDate = props.setDates.toDate;
  }
//---------- Searching-----------------
const [filter, setFilter] = useState([])
const [showClearIcon, setShowClearIcon] = useState("none");
const requestSearch = (searchedVal) => {
  setShowClearIcon(searchedVal === "" ? "none" : "flex");
  document.getElementById('searchtextSecDet').value = searchedVal === "" ? "" : searchedVal;
  const filteredRows = ProductionSecDet_DATA.filter((row) => {
    return row.ItemDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
  });
  if (searchedVal.length < 1) {
    setFilter(ProductionSecDet_DATA)
  }
  else {
    setFilter(filteredRows)
  }
};
  //-----------------TOTAL
  //----TOTAL----------------------
  let total_NoofBales = 0; // Total No Of Bales
  let total_Weight = 0; // Total Weight
  let total_Per = 0; // Total Per

  ProductionSecDet_DATA.map((row) => (total_NoofBales += row.NoOfBales, total_Weight += row.ItemWeight, total_Per += row.Percentage));



  return (
    <div>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
        <HeaderSM category="" title={props.SecDetail.Sec_Desc + " Detail"} />
        <div style={{ marginTop: "-1.5rem" }}>
        <TextField id='searchtextSecDet' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => requestSearch("")}
                      style={{ display: showClearIcon }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  )
                }}
              />
              </div>
        <TableContainer className={classes.container}>
          <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
            <TableHead >
              <TableRow>
                <StyledTableHeadCell align="left">Item Name</StyledTableHeadCell>
                <StyledTableHeadCell align="right">No. Of Bales</StyledTableHeadCell>
                <StyledTableHeadCell align="right">Per%</StyledTableHeadCell>
                <StyledTableHeadCell align="right">Weight</StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filter.map((row) => (
                <StyledTableRow key={row.Id} hover >
                  <StyledTableCell align="left">{row.ItemDesc}</StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.NoOfBales} decimal={0} /></StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.Percentage} decimal={2} />%</StyledTableCell>
                  <StyledTableCell align="right"><NumberFormat value={row.ItemWeight} decimal={2} /></StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Total</StyledTableFooterCell>
                <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_NoofBales} decimal={0} /></StyledTableFooterCell>
                <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Per} decimal={2} />%</StyledTableFooterCell>
                <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Weight} decimal={2} /></StyledTableFooterCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default ProdFGSecDetail