
import { React, useState } from 'react';
import { OrderSumm,OrderDetail,OrderPieChart } from '../';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@mui/material';
import '../../App.css';

const OrderAnalysis = () => {
    const [activeCompoennt, setactiveCompoennt] = useState('OrderPieChart');
    const [getOrderDetail, setOrderDetail] = useState({OrderNo: 0, OrderIntRefNo: '', OrderDate:''});
    const Changepage = (compName) => {
        setactiveCompoennt(compName);
      }
     
  return (
    <div>
          <div className="set-breadcrumb">
          <Breadcrumbs aria-label="breadcrumb">
            <Button className="hover-underline-animation" style={{ color: activeCompoennt !== 'OrderPieChart' &&'purple' }}  disabled={activeCompoennt === 'OrderPieChart' && true} onClick={(e) => Changepage('OrderPieChart')}>
              Order Charts
            </Button>
           <Button className="hover-underline-animation" style={{ color: activeCompoennt !== 'OrderSumm' && 'purple' }}  disabled={activeCompoennt === 'OrderSumm' && true} onClick={(e) => Changepage('OrderSumm')}>
              Order Summary
            </Button>
            {activeCompoennt === 'OrderDetail' &&
              <Button disabled={true}> Order Detail </Button>
            }
          </Breadcrumbs>
        </div>
        {activeCompoennt === 'OrderSumm' &&  <OrderSumm getOrderNo={setOrderDetail} actvCompName={setactiveCompoennt} />}
        {activeCompoennt === 'OrderPieChart' && <OrderPieChart getOrderNo={setOrderDetail} actvCompName={setactiveCompoennt} />}    
        {activeCompoennt === 'OrderDetail' && <OrderDetail  setOrderNo={getOrderDetail}/>}    
    </div>
  )
}

export default OrderAnalysis