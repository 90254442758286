import { React, useState, useEffect } from 'react';

import { HeaderSM, StyledTableRow, StyledTableHeadCell, StyledTableCell, NumberFormat } from '../../components';

import { BrowserRouter as Router, Route, Link, NavLink, Routes, json } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import '../../App.css';
import { SlNote } from 'react-icons/sl';
import { MdFactCheck } from 'react-icons/md';
import { IconContext } from "react-icons";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
   // maxHeight: 440,
   maxHeight: '90vh'

  }
});

const { format } = require('date-fns');

const OrderSumm = (props) => {

  const changecomponent = (rowdata) => {

    props.getOrderNo({ 'OrderNo': rowdata.OrderNo,'OrderIntRefNo':rowdata.OrderIntRefNo,'OrderDate':rowdata.OrderDate });
    props.actvCompName('OrderDetail');

  }
  const classes = useStyles();

  const [OrderSumm, handleStats] = useState([]);

  useEffect(() => {
      FetchData()
  }, [])

  const FetchData = async () => {
   const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/SaleOrderSumm/'); //data source
    console.log("hello fetch")
    const OrderSumm = await data.json()
    localStorage.setItem('OrderSumm-data', JSON.stringify(OrderSumm));
    handleStats(OrderSumm)
    setFilter(OrderSumm);
  }

  // ------------------------- searching------------------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredRows = OrderSumm.filter((row) => {
      return row.OrderNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.OrderCustomer.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.OrderIntRefNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
      // || row.CurrCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
    });
    if (searchedVal.length < 1) {
      setFilter(OrderSumm)
    }
    else {
      setFilter(filteredRows)
    }
  };

  //----pagination---------------------

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    //requestSearch
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
      <HeaderSM category="" title="Sale Order Analysis (Summary)" />
      <div style={{ marginTop: "-2.3rem" }}>
        <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => requestSearch("")}
                style={{ display: showClearIcon }}
              >
                <CancelRoundedIcon />
              </IconButton>
            )
          }}
        />
      </div>


      <TableContainer className={classes.container}>
        <Table id="myTable" stickyHeader aria-label="sticky table" size={'small'}>
          <TableHead >
            <TableRow>

              {/* <StyledTableHeadCell align="left">Date</StyledTableHeadCell> */}
              <StyledTableHeadCell align="left">Customer & Int. Ref.</StyledTableHeadCell>
              {/* <StyledTableHeadCell align="left">Int. Ref.</StyledTableHeadCell> */}
              <StyledTableHeadCell align="center">Order <br/> Ready</StyledTableHeadCell>
              {/* <StyledTableHeadCell align="center">Ready</StyledTableHeadCell> */}
              <StyledTableHeadCell align="center">Balance</StyledTableHeadCell>
              <StyledTableHeadCell align="left">Order No. & Date</StyledTableHeadCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {filter.map((row) => (

              <StyledTableRow key={row.Id} hover >
                <StyledTableCell align="left">
                <Button style={{ fontWeight: "400", textAlign: "left"  }} size='small' color="inherit" variant='text' onClick={(e) => changecomponent(row)}>
                  {row.OrderCustomer}<br />
                  {row.OrderIntRefNo} </Button></StyledTableCell> 
                <StyledTableCell align="center" style={{padding:"inherit"}}><IconContext.Provider value={{ style: { display: "inline" }}}><SlNote size={15} /></IconContext.Provider> <NumberFormat value={row.OrderQnty} decimal={0}/><br/> 
                                                                            <IconContext.Provider value={{ style: { display: "inline" }}}><MdFactCheck size={18}/></IconContext.Provider> <NumberFormat value={row.ReadyQnty} decimal={0}/>
                </StyledTableCell>
                {/* <StyledTableCell align="center"><NumberFormat value={row.ReadyQnty} decimal={0} /></StyledTableCell> */}
                <StyledTableCell align="center"><NumberFormat value={row.BalanceQnty} decimal={0} /></StyledTableCell>
                <StyledTableCell align="left">
                  <Button style={{ fontWeight: "400", textAlign: "left"  }} size='small' color="inherit" variant='text' onClick={(e) => changecomponent(row)}>
                    {row.OrderNo}<br />
                    {format(new Date(row.OrderDate), 'dd/MM/yyyy')}
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  )
}

export default OrderSumm

