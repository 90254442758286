import { React, useState, useEffect, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { Header, StyledTableRow, StyledTableHeadCell, StyledTableCell,StyledTableFooterCell, NumberFormat } from '../../components';
import '../../App.css';
import "react-datepicker/dist/react-datepicker.css";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField, TableContainer, TableFooter
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { makeStyles } from "@material-ui/core/styles";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { subMonths } from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { MdOutlineFilterList } from "react-icons/md";


const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    // maxHeight: 440,
    maxHeight: '90vh'
  },
  TableCell: {
    border: "1px solid rgba(0,0,0,0.2)",
    padding: 4
  }
});


const { format } = require('date-fns');

const OrderDetail = (props) => {
  const classes = useStyles();

  // const location = useLocation();
  // const state = location.state;

  let showData = true;

  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [OrderDetail, handleStats] = useState([]);

  useEffect(() => {
    FetchData()
  }, [])
  // let OrderNo = 0;
  // const fOrderNo = state ? state.OrderNo : null;
  // OrderNo = fOrderNo ? fOrderNo : OrderNo;
  const FetchData = async () => {
    const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/SaleOrderDetail?sohrefno=' + props.setOrderNo.OrderNo); //data source  
    const OrderDetail = showData ? await data.json() : [];
    handleStats(OrderDetail)
    setFilter(OrderDetail);
  }


//   if (props.setOrderNo.OrderNo !== PrevOrderNo) {
  
//     useEffect(() => {
//       FetchData()
//     }, [])
//   console.log('yaha')
// }
  //----TOTAL----------------------
  let total_Bales = 0;  
  let total_Ready = 0; 
  let total_Bal = 0;  

  OrderDetail.map((row) => (total_Bales += row.SOdBales));
  OrderDetail.map((row) => (total_Ready += row.Ready));
  OrderDetail.map((row) => (total_Bal += row.BalanceQnty));

  // ------------------------- searching------------------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal=== "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ?  "" :  searchedVal;
    const filteredRows = OrderDetail.filter((row) => {
      return row.ItemDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
  });
    if (searchedVal.length < 1) {
      setFilter(OrderDetail)
    }
    else {
      setFilter(filteredRows)
    }
  };
  //----pagination---------------------

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //-----export pDf---------------------------

//   console.log (state)

  return (
    <>
      { (
        <div>
         
          <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
            <Header category="" title="Order Detail" OtherInfo={"Order # : " + props.setOrderNo.OrderIntRefNo +" Date : "+  format(new Date(props.setOrderNo.OrderDate), 'dd/MM/yyyy')}/>
             {/* + " - " + format(new Date(state.OrderDate), 'dd/MM/yyyy')} OtherInfo1 = { "Customer : " + state.OrderCustomer + " - " + state.OrderIntRefNo}/> */}


            {error && <Collapse in={open}>  <Alert severity="warning" onClose={() => { setOpen(false) }}>From Date should be less than To Date</Alert></Collapse>}

            <div style={{ marginTop: "-1.5rem" }}>
            
              <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => requestSearch("")}
                      style={{ display: showClearIcon }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                  )
                }}
              />
            </div>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCell align="left">Item</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Bales</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Ready</StyledTableHeadCell>
                    <StyledTableHeadCell align="right">Balance</StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                  {filter.map((row) => (
                    <StyledTableRow key={row.Id}>

                      <StyledTableCell align="left">{row.ItemDesc}</StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.SOdBales} decimal={0}/></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.Ready} decimal={0}/></StyledTableCell>
                      <StyledTableCell align="right"><NumberFormat value={row.BalanceQnty} decimal={0}/></StyledTableCell>
                    </StyledTableRow>
                  ))}

                  <StyledTableRow>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Total Balance</StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Bales} decimal={0}/></StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Ready} decimal={0}/></StyledTableFooterCell>
                    <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Bal} decimal={0}/></StyledTableFooterCell>
                  </StyledTableRow>

                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
            count={filter.length}
            showFirstButton
            showLastButton
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            //   onChangeRowsPerPage={handleChangeRowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          </div>
        </div>
      )}
    </>
  )

}



export default OrderDetail