import { React, useState, useEffect, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { Header, StyledTableRow, StyledTableHeadCell, StyledTableCell, StyledTableFooterCell, NumberFormat } from '../../components';
import '../../App.css';
import "react-datepicker/dist/react-datepicker.css";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField, TableContainer, TableFooter
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { makeStyles } from "@material-ui/core/styles";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { subMonths, toDate } from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { MdOutlineFilterList } from "react-icons/md";



const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    // maxHeight: 440,
    maxHeight: '90vh'
  },
  TableCell: {
    border: "1px solid rgba(0,0,0,0.2)",
    padding: 4
  }
});


const { format } = require('date-fns');
let prevfrmdate;
let prevtodate;
const FinancialGL = (props) => {

  const classes = useStyles();

  const location = useLocation();
  const state = location.state;

  let showData = true;

  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [GLATA, handleStats] = useState([]);
  const [frmdate, setfrmDate] = useState(subMonths(new Date(), 12));
  const [todate, settoDate] = useState(new Date());

  useEffect(() => {
    FetchData()
  }, [])

  // const todaydate = format(new Date(), 'yyyy-MM-dd');//moment();

  // const PrevYeardate = format(subMonths(new Date(), 12), 'yyyy-MM-dd');//moment().subtract(1, 'year')

  // // console.log(PrevYeardate);
  // let frmGLDate = PrevYeardate;
  // let toGLDate = todaydate;

  function getDates() {
    const $frmDate = format(new Date(frmdate), 'yyyy-MM-dd');
    const $toDate = format(new Date(todate), 'yyyy-MM-dd');
    // const $frmDate = $('#frmDate');
    // const $toDate = $('#toDate');
    if ($frmDate > $toDate) {
      setError(true);
      setOpen(true);
      showData = false;

    }
    else {
      showData = true;
      setError(false);
      setOpen(false);
      setfrmDate($frmDate);
      settoDate($toDate);
    }
    FetchData();
  }
  const FetchData = async () => {

    const data = await fetch(process.env.REACT_APP_SERVER_NAME + '/api/generalledger?AcCode=' + props.setGLFilter.AcCode + '&CcCode=' + props.setGLFilter.CcCode + '&FrmDate=' + format(new Date(frmdate), 'yyyy-MM-dd') + '&ToDate=' + format(new Date(todate), 'yyyy-MM-dd')); //data source  
    const GLATA = showData ? await data.json() : [];
    handleStats(GLATA)
    setFilter(GLATA);
    // console.log(GLATA)
  }
  if(prevfrmdate !== frmdate || prevtodate !== todate)
  {
    FetchData();
    prevfrmdate = frmdate;
    prevtodate = todate;
  }
  //----TOTAL----------------------
  let total_Bal = 0; // Total Calories

  GLATA.map((row) => (total_Bal += row.Balance));

  //---------- Searching-----------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal === "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ? "" : searchedVal;
    const filteredRows = GLATA.filter((row) => {
      return row.VchrDate.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.VchrType.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.VchrNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.VchrNarr.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.VchrChqNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    });
    if (searchedVal.length < 1) {
      setFilter(GLATA)
    }
    else {
      setFilter(filteredRows)
    }
  };
  //----pagination---------------------

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //-----export pDf---------------------------

  return (
    <>

      <div>
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
          <Header category="" title="General Ledger" OtherInfo={"Account : " + props.setGLFilter.CoaDesc} OtherInfo1={"Balance : " + total_Bal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/-(.*)/, "($1)")} />

          <div style={{ marginTop: "-1.5rem" }}>
            {error && <Collapse in={open}>  <Alert severity="warning" onClose={() => { setOpen(false) }}>From Date should be less than To Date</Alert></Collapse>}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="From Date"
                autoComplete='off'
                value={frmdate}
                onChange={(newValue) => {
                  if (newValue > todate) {
                    setError(true);
                    setOpen(true);
                  }
                  else {
                    setError(false);
                    setOpen(false);
                    if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                      setfrmDate(newValue);
                      
                    }
                  }
                }}
                inputFormat={"DD/MM/YYYY"}
                renderInput={(params) => <TextField {...params} />}
                className="sameLineTabledatetime"
              />
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <DesktopDatePicker
                label="To Date"
                value={todate}
                onChange={(newValue) => {
                  if (frmdate > newValue) {
                    setError(true);
                    setOpen(true);
                  }
                  else {
                    setError(false);
                    setOpen(false);
                    if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                      settoDate(newValue);
                    }
                  }
                }}

                inputFormat={"DD/MM/YYYY"}
                renderInput={(params) => <TextField {...params} />}
                className="sameLineTabledatetime"
              />
            </LocalizationProvider>
            {/* 
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="From Date"
                  value={frmdate}
                  onChange={(newValue) => {
                    setfrmDate(newValue);
                  }}
                  inputFormat={"dd/MM/yyyy"}
                  renderInput={(params) => <TextField {...params} />}
                  className="datetime"
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <DatePicker
                  label="To Date"
                  id="toDate"
                  value={todate}
                  onChange={(newValue) => {
                    settoDate(newValue);
                  }}
                  inputFormat={"dd/MM/yyyy"}
                  renderInput={(params) => <TextField {...params} />}
                  className="datetime"
                />
              </LocalizationProvider> */}
            <ButtonGroup variant="text" size="" aria-label="outlined primary button group">
              <Button className='filterButton' onClick={getDates}><MdOutlineFilterList size={25} position={10} color='purple' /></Button>

            </ButtonGroup>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

            <br />

            <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small" onChange={(e) => requestSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => requestSearch("")}
                    style={{ display: showClearIcon }}
                  >
                    <CancelRoundedIcon />
                  </IconButton>
                )
              }}
            />
          </div>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell align="left">Date</StyledTableHeadCell>
                  {/* <StyledTableHeadCell align="center">Type</StyledTableHeadCell> */}
                  {/* <StyledTableHeadCell align="left">Voucehr No</StyledTableHeadCell> */}
                  <StyledTableHeadCell align="left">Narration</StyledTableHeadCell>
                  {/* <StyledTableHeadCell align="left">Chq No.</StyledTableHeadCell> */}
                  <StyledTableHeadCell align="right">Balacne</StyledTableHeadCell>
                  {/* <StyledTableHeadCell align="right">Debit</StyledTableHeadCell> */}
                  {/* <StyledTableHeadCell align="right">Credit</StyledTableHeadCell> */}
                  {/* <StyledTableHeadCell align="right">Running Total</StyledTableHeadCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                {filter.map((row) => (
                  <StyledTableRow key={row.Id}>
                    {/* <TableCell component="th" scope="row">
            {GLATA.indexOf(row) + 1}
          </TableCell> */}
                    <StyledTableCell align="left" >
                      <div className='smallFont'>{row.VchrType} {row.VchrNo}</div>
                      {format(new Date(row.VchrDate), 'dd/MM/yyyy')}

                    </StyledTableCell>
                    {/* <StyledTableCell align="center">{row.VchrType}</StyledTableCell> */}
                    {/* <StyledTableCell align="left">{row.VchrNo}</StyledTableCell> */}
                    <StyledTableCell align="left">{row.VchrNarr}  {row.VchrChqNo !== "" ? "- Chq No.: " + row.VchrChqNo : ""}</StyledTableCell>
                    {/* <StyledTableCell align="left">{row.VchrChqNo}</StyledTableCell> */}
                    {/* <StyledTableCell align="right"><NumberFormat value = {row.Debit} /></StyledTableCell> */}
                    {/* <StyledTableCell align="right"><NumberFormat value = {row.Credit} /></StyledTableCell> */}
                    <StyledTableCell align="right"><NumberFormat value={row.Balance} decimal={2} /></StyledTableCell>
                    {/* <StyledTableCell align="right"><NumberFormat value = {row.RunningBalance} /></StyledTableCell> */}
                  </StyledTableRow>
                ))}

                <StyledTableRow>
                  <StyledTableFooterCell></StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}>Total Balance</StyledTableFooterCell>
                  <StyledTableFooterCell align="right" style={{ fontWeight: "Bold" }}><NumberFormat value={total_Bal} decimal={2} /></StyledTableFooterCell>
                </StyledTableRow>

              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
            count={filter.length}
            showFirstButton
            showLastButton
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            //   onChangeRowsPerPage={handleChangeRowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </div>
      </div>

    </>
  )

}



export default FinancialGL