import { React, useState, useEffect } from 'react';

import { HeaderSM,StyledTableRow,StyledTableHeadCell,StyledTableCell,NumberFormat } from '../../components';

import { BrowserRouter as Router, Route, Link, NavLink, Routes } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel
} from "@material-ui/core";
import TablePagination from '@mui/material/TablePagination';
import TextField from "@mui/material/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import '../../App.css';




const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    // maxHeight: 440
    maxHeight: '90vh'
  }
});


const FinancialTB = (props) => {

  const changecomponent = (rowdata) => {
    props.getGLFilter({ 'AcCode': rowdata.AcCode,'CcCode':rowdata.CcCode,'CoaDesc':rowdata.CoaDesc });
    props.actvCompName('GL');

  }

  const classes = useStyles();

  const [TBDATA, handleStats] = useState([]);

  useEffect(() => {
    FetchData()
  }, [])


  const FetchData = async () => {
    const data = await fetch(process.env.REACT_APP_SERVER_NAME+'/api/FinancialAnalysisTB/'); //data source
    const TBDATA = await data.json()
    handleStats(TBDATA)
    setFilter(TBDATA);
  }
  // ------------------------- searching------------------------
  const [filter, setFilter] = useState([])
  const [showClearIcon, setShowClearIcon] = useState("none");
  const requestSearch = (searchedVal) => {
    setPage(0);
    setShowClearIcon(searchedVal=== "" ? "none" : "flex");
    document.getElementById('searchtext').value = searchedVal === "" ?  "" :  searchedVal;
    const filteredRows = TBDATA.filter((row) => {
      return row.CoaDesc.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.AcCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.CcCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
        || row.CurrCode.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
  });
    if (searchedVal.length < 1) {
      setFilter(TBDATA)
    }
    else {
      setFilter(filteredRows)
    }
  };

    //----pagination---------------------

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
//requestSearch
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl page">
      <HeaderSM category="" title="Trial Balance" />
      <div style={{marginTop:"-2.3rem"}}>
      <TextField id='searchtext' placeholder="Search" type="text" variant="outlined" fullWidth size="small"  onChange={(e) => requestSearch(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => requestSearch("")}
              style={{ display: showClearIcon }}
            >
              <CancelRoundedIcon />
            </IconButton>
          )
        }}
      />
      </div>


      <TableContainer className={classes.container}>
        <Table id="myTable" stickyHeader aria-label="sticky table"  size={'small'}>
          <TableHead >
            <TableRow>
              {/* <StyledHeadTableCell align="left">Account Code</StyledHeadTableCell> */}
              {/* <StyledHeadTableCell align="left">C.C. Code</StyledHeadTableCell> */}
              <StyledTableHeadCell align="left">Account Description</StyledTableHeadCell>
              {/* <StyledTableHeadCell align="center">Currency</StyledTableHeadCell> */}
              <StyledTableHeadCell align="right">Balance</StyledTableHeadCell>
              {/* <StyledTableHeadCell align="right">Credit</StyledTableHeadCell>
              <StyledTableHeadCell align="right">Debit</StyledTableHeadCell> */}
              {/* <StyledTableHeadCell align="left">Ledger</StyledTableHeadCell> */}
   
            </TableRow>
          </TableHead>
          <TableBody>
          {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
          {filter.map((row) => ( 
              <StyledTableRow key={row.AcCode} hover >
                {/* <TableCell component="th" scope="row">
                {TBDATA.indexOf(row) + 1}
              </TableCell> */}
                {/* <TableCell align="left">{row.AcCode}</TableCell> */}
                {/* <TableCell align="left">{row.CcCode}</TableCell> */}
                {/* component={Link} to="/FinancialGL/" state={row}  */}
                <StyledTableCell align="left"><Button style={{fontWeight:"400", textAlign: "left" }} size='small' color="inherit" variant='text' onClick={(e) => changecomponent(row)}>{row.CoaDesc + "-" +row.CurrCode}</Button></StyledTableCell>
                {/* <StyledTableCell align="center">{row.CurrCode}</StyledTableCell> */}
                <StyledTableCell align="right"><NumberFormat value = {row.TrdActAmnt} decimal = {2} /></StyledTableCell>
                {/* <StyledTableCell align="right">{(row.TrdDflAmnt_Cr).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</StyledTableCell>
                <StyledTableCell align="right">{(row.TrdDflAmnt_Dr).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</StyledTableCell> */}
                {/* <StyledTableCell align="left"><Button color="inherit" component={Link} to="/FinancialGL/" state={row}><HiOutlineDotsCircleHorizontal style={{color:"purple"}}/></Button></StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
{/*     
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100,{ label: 'All', value: -1 }]}
            count={filter.length}  
            showFirstButton 
            showLastButton
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
         //   onChangeRowsPerPage={handleChangeRowsPerPage}
             onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
                 
    </div>
  )
}

export default FinancialTB

