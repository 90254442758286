import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route,Navigate,HashRouter } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi'
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import {Navbar,Footer,Sidebar,ThemeSettings} from './components';
import {FinancialAnalysis,ProductionAnalysis,OrderAnalysis} from './pages';
import { useStateContext } from './contexts/ContextProvider';
import './App.css'

const App = () => {
    const {activeMenu}=useStateContext();

    return (
        <div>
            <HashRouter forceRefresh={true}>
                <div className="flex relative dark:bg-main-dark-bg ">
                    {/* <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
                        <TooltipComponent content="Settings" position="Top">
                            <button type="button" className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white" style={{ background: 'blue', borderRadius: '50%' }}>
                                <FiSettings />
                            </button>
                        </TooltipComponent>
                    </div> */}
                    {activeMenu ? (
                        <div className="w-72 fixed sidebar dark:bg-secondart-dark-bg bg-white ">
                            <Sidebar />
                        </div>
                    ) : (
                        <div className="w-0 dark:bg-secondary-dark-bg">
                            <Sidebar />
                        </div>

                    )}
                    <div className={activeMenu ?  'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full ':'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2'}>
                        <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                        <Navbar />
                        </div>
                    <div>
                        <Routes>
                            {/* dahboard */}
                            <Route path="/" element={ <Navigate to="/FinancialAnaylysis" /> } />

                            {/* <Route path= "/"  element={<FinancialAnalysis />} > </Route> */}
                            <Route path="*" element={<FinancialAnalysis />} />

                            {/* pages */}
                            <Route path="/FinancialAnalysis" element={<FinancialAnalysis />} />
                            <Route path="/ProductionAnalysis" element={<ProductionAnalysis ProdType={'Rags'}/>} />
                            <Route path="/ProductionAnalysisWiper" element={<ProductionAnalysis ProdType={'Wiper'}/>} />
                            <Route path="/OrderAnalysis" element={<OrderAnalysis />} />

                            {/* Apps */}
                          {/*  <Route path="/kanabn" element={<Kanban />} />
                            <Route path="/editor" element={<Editor />} />
                            <Route path="/Calander" element={<Calendar />} />
                            <Route path="/color-picker" element={<ColorPicker />} /> */}

                            {/* charts */}
                           {/* <Route path="/line" element={<Line />} />
                            <Route path="/area" element={<Area />} />
                            <Route path="/bar" element={<Bar />} />
                            <Route path="/pie" element={<Pie />} />
                            <Route path="/financial" element={<Financial />} />
                            <Route path="/color-mapping" element={<ColorMapping />} />
                            <Route path="/pyramid" element={<Pyramid />} />
                            <Route path="/stacked" element={<Stacked />} /> */}



                        </Routes>
                    </div>
                    </div>

                </div>
            </HashRouter>
        </div>
    )
}

export default App